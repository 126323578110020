
import { Component, Vue } from 'vue-property-decorator';
import VueAMap from 'vue-amap';
import { Gopage } from '@/mixins/gopage';
import BottomModule from '@/components/bottomModule/bottomModule.vue';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '49dc9e18f2999a3cf571cdbe7807a2f0',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  uiVersion: '1.0',
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
});

@Component({
  components: { BottomModule },
  mixins: [Gopage],
})
export default class Login extends Vue {
  private activeName: any = 'five';

  private true_code: any = '';
  private yanzheng_arr: any = [];

  private rules: any = {
    phone: [
      { required: true, message: '请填写联系电话', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入联系电话'));
          } else {
            const regex = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
            if (!value || !regex.test(value)) {
              console.log(13123);
              callback(new Error('请输入正确的手机号码'));
            }
            callback();
          }
        },
        trigger: 'blur',
      },
    ],
    name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
  };

  private dataForm: any = {
    name: '',
    phone: '',
    content: '',
    code: '',
  };
  zoom: any = 18;
  center: any = [120.125533, 30.272903];
  private dropdownIcon: any = 'el-icon-s-unfold';

  visibleChange(e: any) {
    this.dropdownIcon = e ? 'el-icon-close' : 'el-icon-s-unfold';
  }

  // beforeDestroy() {
  //   window.scrollTo({ top: 0 });
  // }

  private picList: any = [
    {
      picSrc: require('../../base/aboutUsPic/certificate-two.png'),
    },
    {
      picSrc: require('../../base/aboutUsPic/certificate-three.png'),
    },
    {
      picSrc: require('../../base/aboutUsPic/certificate-one.png'),
    },
  ];

  private licensePic: any = [
    {
      picSrc: require('../../base/aboutUsPic/license-one.png'),
    },
    {
      picSrc: require('../../base/aboutUsPic/license-Two.png'),
    },
    {
      picSrc: require('../../base/aboutUsPic/license-three.png'),
    },
    {
      picSrc: require('../../base/aboutUsPic/license-four.png'),
    },
    {
      picSrc: require('../../base/aboutUsPic/license-five.png'),
    },
  ];

  /**
   * @desc Vue 创建数据生命周期，获取验证码
   * @param1 暂无
   * @returns 暂无
   */
  created() {
    this.$nextTick(() => {
      this.draw(this.yanzheng_arr);
    });
  }

  confirmData() {
    const formComponent: any = this.$refs.dataForm;
    formComponent.validate((valid: any) => {
      if (!valid) {
        return;
      }
      if (this.dataForm.code != this.true_code) {
        this.$message.warning('验证码错误');
        this.draw(this.yanzheng_arr);
        return;
      }
      this.$message.success('提交成功');
      this.dataForm = { name: '', phone: '', content: '', code: '' };
      // setTimeout(() => {
      //   location.reload()
      // }, 1000);
    });
  }

  draw(show_num) {
    const canvas_width: any = document.querySelector('#canvas').clientWidth;
    const canvas_height: any = document.querySelector('#canvas').clientHeight;
    const canvas: any = document.getElementById('canvas'); //获取到canvas
    const context: any = canvas.getContext('2d'); //获取到canvas画图
    canvas.width = canvas_width;
    canvas.height = canvas_height;
    const sCode: any = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0';
    const aCode: any = sCode.split(',');
    const aLength: any = aCode.length; //获取到数组的长度

    //4个验证码数
    for (let i = 0; i <= 3; i++) {
      const j: any = Math.floor(Math.random() * aLength); //获取到随机的索引值
      const deg: any = (Math.random() * 30 * Math.PI) / 180; //产生0~30之间的随机弧度
      const txt: any = aCode[j]; //得到随机的一个内容
      show_num[i] = txt.toLowerCase(); // 依次把取得的内容放到数组里面
      const x: any = 10 + i * 20; //文字在canvas上的x坐标
      const y: any = 20 + Math.random() * 8; //文字在canvas上的y坐标
      context.font = 'bold 23px 微软雅黑';

      context.translate(x, y);
      context.rotate(deg);

      context.fillStyle = this.randomColor();
      context.fillText(txt, 0, 0);

      context.rotate(-deg);
      context.translate(-x, -y);
    }
    //验证码上显示6条线条
    for (let i = 0; i <= 5; i++) {
      context.strokeStyle = this.randomColor();
      context.beginPath();
      context.moveTo(Math.random() * canvas_width, Math.random() * canvas_height);
      context.lineTo(Math.random() * canvas_width, Math.random() * canvas_height);
      context.stroke();
    }
    //验证码上显示31个小点
    for (let i = 0; i <= 30; i++) {
      context.strokeStyle = this.randomColor();
      context.beginPath();
      const x: any = Math.random() * canvas_width;
      const y: any = Math.random() * canvas_height;
      context.moveTo(x, y);
      context.lineTo(x + 1, y + 1);
      context.stroke();
    }

    //最后把取得的验证码数组存起来，方式不唯一
    const num: any = show_num.join('');
    // console.log(num);
    this.true_code = num;
  }
  //得到随机的颜色值
  randomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
  //canvas点击刷新
  handleCanvas() {
    this.draw(this.yanzheng_arr);
  }
}
